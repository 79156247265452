import React, { useState } from 'react';
import { WTContext } from '../lib/WT';
import Chart from 'react-apexcharts';

import movement from '../images/movement.png';
import throughput from '../images/throughput.png';
import graph1 from '../images/graph1.jpg';
import graph2 from '../images/graph2.jpg';

function MonitorGraph2(props) {
    const WT = React.useContext(WTContext);

    React.useEffect(() => {
        // console.log(props);
        // console.log(props.list);

        return () => {

        }
    }, []);

  
    var options = {
        chart: {
            type: "line",
            toolbar: { show: false }
        },
        grid: {
            row: { colors: ['#383854'], opacity: 0.7 },
            borderColor: '#5c5c71',
        },
        colors: ["#ffbf2c"],
        xaxis: {
            labels: { style: { colors: '#FFFFFF', fontSize: '12px' } },
            // categories: [1111, 2222, 3333, 4444],
            categories: props.list.map(a => a["tm"].split(':').slice(0, 2).join(':')),
            tickAmount: 10
        },
        yaxis: {
            // categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
            labels: { 
                    style: { colors: '#FFFFFF', fontSize: '10px' } ,
                    formatter(v){
                        return v.toFixed(1);
                    }
            },
            //forceNiceScale: true,
            max: Number(props.max),
            min: Number(props.min)
        },
        plotOptions: {
            labels: { show: false, }
        },
    }

    var series = [{
        name: "동작 확인",
        // data: [2, 10, 6],
        data: props.list.map(a => a["col"]),
        // data: graph2.map(a => a["weight"]),
    }]

    return (
        <Chart type="line" series={series} options={options} height="240" />
    )

}

export default MonitorGraph2;