import React from 'react';
import {createRoot} from 'react-dom/client';
import './lib/extension';
import App from './App';
import './index.css';
import { CookiesProvider } from 'react-cookie';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <CookiesProvider>
        <App />
    </CookiesProvider>
);