import React, { useState } from 'react';
import { WTContext } from '../lib/WT';
import status from '../images/status.png';
import monitoring from '../images/monitoring.png';
import error from '../images/error.png';
import sns from '../images/sns.png';
import logout from '../images/logout.png';
import alertify from 'alertifyjs';
import StatusCmp from './status';
import MonitorCmp from './monitoring';
import ErrorCmp from './error';
import SnsCmp from './sns';
import LoginCmp from './login';
import Admin from './admin';


function FixCmd(props) {
    const WT = React.useContext(WTContext);

    React.useEffect(() => {

        return () => {

        }
    }, []);

    const [gubun, setGubun] = useState(1);
    function tab(gubun) {
        setGubun(gubun);
    }

    const [unit, setUnit] = useState(0);
    const [nm, setNm] = useState('');

    function logoutAlert() {
        alertify.confirm("VITZRO NEXTTECH", "로그아웃 하시겠습니까?", function () {
            sessionStorage.removeItem("id");
            window.location.reload();
        }, function () {

        });
    }

    if (gubun === 0) {
        return <LoginCmp />
    } else {
        return <div className="contents_wrap">
            <aside className="left">
                <p className="main_title">탄화기</p>

                <div className="left_top">
                    <nav>
                        <ul>
                            <li className={`${gubun === 1 ? 'open' : ''}`}><a onClick={() => tab(1)}><img src={status} alt="전체 현황" />전체 현황</a></li>
                            <li className={`${gubun === 2 ? 'open' : ''}`}><a onClick={() => tab(2)}><img src={monitoring} alt="전체 현황" />상세 모니터링</a></li>
                            <li className={`${gubun === 3 ? 'open' : ''}`}><a onClick={() => tab(3)}><img src={error} alt="전체 현황" />Error Messages</a></li>
                        </ul>
                    </nav>
                </div>

                <div className="left_btm">
                    <ul>
                        {/* <li className="sns_link"> */}
                        <li className={`sns_link ${gubun === 4 ? 'open' : ''}`} onClick={() => tab(4)}>
                            <div className="left_btm_img"></div>
                            <a>SMS 알림세팅</a>
                        </li>

                        {sessionStorage.getItem("id") == "admin" ?
                            <li className={`admin_link ${gubun === 5 ? 'open' : ''}`} onClick={() => tab(5)}>
                                <div className="left_btm_img"></div>
                                <a>계정관리</a>
                            </li>
                            :
                            <></>
                        }

                        {sessionStorage.getItem("id") ?
                            <li className="logout_link" onClick={() => logoutAlert()}>
                                <div className="left_btm_img"></div>
                                <a>logout</a>
                            </li>
                            :
                            <li className="logout_link" onClick={() => tab(0)}>
                                <div className="left_btm_img"></div>
                                <a>login</a>
                            </li>
                        }

                    </ul>
                </div>
            </aside>

            {/* {gubun === 2 ? <MonitorCmp unit={unit} nm={nm} /> : */}
            {gubun === 2 ? <MonitorCmp unit={unit} tab={tab} /> :
                gubun === 3 ? <ErrorCmp /> :
                    gubun === 4 ? <SnsCmp /> :
                        gubun === 5 ? <Admin /> :
                            <StatusCmp setUnit={setUnit} setGubun={setGubun} />
            }

        </div>
    }

}

export default FixCmd;