import React from 'react'
import parse from "html-react-parser";

export const _HTML = (s) => {
    return parse(s);
}

export const isObject = function(obj)
{
    return obj !== undefined && obj !== null && obj.constructor === Object;
}

export const isArray = function(obj)
{
    return obj !== undefined && obj !== null && obj.constructor === Array;
}

export const isBoolean = function(obj)
{
    return obj !== undefined && obj !== null && obj.constructor === Boolean;
}

export const isFunction = function(obj)
{
    return obj !== undefined && obj !== null && obj.constructor === Function;
}

export const isNumber = function(obj)
{
    return obj !== undefined && obj !== null && obj.constructor === Number;
}

export const isString = function(obj)
{
    return obj !== undefined && obj !== null && obj.constructor === String;
}

export const isDate = function(obj) {
    return obj !== undefined && obj !== null && obj.constructor === Date;
    //return typeOf(obj) === 'date' && !isNaN(obj.getTime());
}

export const _$ = (id) => {
    return document.getElementById(id);
}

export const _PD = (jb) => {
    let ss = [];
    for(var k in jb) {
        ss.push("{0}={1}".format(k, jb[k]));
    }

    return ss.join("&");
}

export const _T = function(el) {
    el = el || '';
    if (el === '') return el;

    if (el.options) {
        return (el.options[el.selectedIndex].value || el.options[el.selectedIndex].textContent);
    }

    el = el[0] || el;

    return (el.value || el.textContent || el.innerText || "");    
}

export const _TS = function(el, v) {
    if (el == null || el == 'undefiend') return;
    
    if(el.options) {
        for(var i = 0; i < el.options.length; i++) {
            if(el.options[i].value == v || el.options[i].textContent == v) {
                el.selectedIndex = i;
                return;
            }
        }
    }
    else
    {
        el = el[0] || el;
        if (el.hasOwnProperty("value")) el.value = v;
        else el.innerHTML = v;
    }
}


export const _RATE = function(v) {
    let n = _N(v);
    if( n > 0) return "+"+n.toFixed(2);
    return n.toFixed(2);
}

export const _RATA = function(v) {
    let n = _N(v);
    return Math.abs(n).toFixed(2);
}


export const _N = function(el) {
    if (isNumber(el)) {
        return el;
    }

    var s = (isString(el)) ? el : _T(el);
    var nn = "";
    for (var i = 0; i < s.length; i++) {
        var k = s.charAt(i);
        if ((k >= '0' && k <= '9') || k === '.') {
            nn += k;
        }
    }
    var n = Number(nn);
    if (s.charAt(0) === '-') { n = -1 * n; }
    return (n || 0);
}

export const _M = function(v, zero, abs) {
    zero = zero || '';

    v = String(v);
    if (v === 'undefined') {
        return zero;
    }    
    
    var ps = v.split('.');
    var whole = ps[0];
    var r = /(\d+)(\d{3})/;
    while (r.test(whole)) {
        whole = whole.replace(r, '$1' + ',' + '$2');
    }
    v = whole;
    if (v.charAt(0) === '-') {
        if(abs) {
            return v.substr(1);
        }
        return '-' + v.substr(1);
    }
    
    if (v === "0") { return zero; }
    return "" + v;
}

export const _JSON = function(str) {
    try {
        if(isObject(str)) return str;
        if(isString(str)) return JSON.parse(str);
        return str;
    } catch(err) {
        return {exception:err};
    }
}

export const _S = function(obj) {
    if (isString(obj)) return obj;
    if (isObject(obj)) return JSON.stringify(obj);
    return String(obj);    
}

export const _NOTAG = function(str) {
    var rtn = str.replace(/(<([^>]+)>)/ig,"");
    return rtn.replace("&nbsp;", " ");
}

export const _CP = function(src, target, overwrite) {
    overwrite = overwrite || true;

    for(var k in src) {
        if(overwrite) {
            if (isObject(src[k])) {
                _CP(src[k], target[k]);
            } else {
                target[k] = src[k];
            }
        } else {
            if(!target.hasOwnProperty(k)) {
                if (isObject(src[k])) {
                    _CP(src[k], target[k], overwrite);
                } else {
                    target[k] = src[k];
                }
            }
        }
    }
}

export const _CT = function(src, target) {
    for(var k in target) {
        if(target.hasOwnProperty(k)) {
            if (isObject(src[k])) {
                _CP(src[k], target[k]);
            } else {
                target[k] = src[k];
            }
        }
    }        
}

export const _WEEKDAY = function(d) {
    var w = d.getDay();
    switch (w) {
        case 0:
            return "일";
        case 1:
            return "월";
        case 2:
            return "화";
        case 3:
            return "수";
        case 4:
            return "목";
        case 5:
            return "금";
        case 6:
            return "토";
    }
    return "X";
}

export const lpad = function(number, length) {

    var str = '' + number;
    while (str.length < length) {
        str = '0' + str;
    }

    return str;
}

export const _YMD = function(str, pt) {
    let v = lpad(_N(str), 8);
    pt = pt || ".";
    return "{1}{0}{2}{0}{3}".format(pt, v.substring(0, 4), v.substring(4, 6), v.substring(6, 8));
}

export const _HMS = function(str, pt) {
    let v = lpad(_N(str), 6);
    pt = pt || ":";
    return "{1}{0}{2}{0}{3}".format(pt, v.substring(0, 2), v.substring(2, 4), v.substring(4, 6));
}

export const date_format = function(dt, format) {
    var Y = dt.getFullYear().toString();
    var m = lpad(dt.getMonth() + 1, 2);
    var d = lpad(dt.getDate(), 2);
    var H = lpad(dt.getHours(), 2);
    var i = lpad(dt.getMinutes(), 2);
    var s = lpad(dt.getSeconds(), 2);

    return String(format).replace('%Y', Y).replace('%m', m).replace('%d', d).replace("%h", H).replace('%i', i).replace('%s', s);
}

export const date_format_before = function(dt, format) {
    var Y = dt.getFullYear().toString();
    var m = lpad(dt.getMonth(), 2);
    var d = lpad(dt.getDate(), 2);
    var H = lpad(dt.getHours(), 2);
    var i = lpad(dt.getMinutes(), 2);
    var s = lpad(dt.getSeconds(), 2);

    return String(format).replace('%Y', Y).replace('%m', m).replace('%d', d).replace("%h", H).replace('%i', i).replace('%s', s);
}


export const _hogaUnit = function(prc, market, etf) {
    var etfgubun = etf || 0;

    if (etfgubun > 0)
    {
        return 5;
    }
    else if (market === 2) {
        if (prc >= 50000) {
        return 100;
        }
        else if (prc >= 10000) {
        return 50;
        }
        else if (prc >= 5000) {
        return 10;
        }
        else if (prc >= 1000) {
        return 5;
        }
        else {
        return 1;
        }
    }
    else if (market === 1)
    {
        if (prc >= 500000) {
        return 1000;
        }
        else if (prc >= 100000) {
        return 500;
        }
        else if (prc >= 50000) {
        return 100;
        }
        else if (prc >= 10000) {
        return 50;
        }
        else if (prc >= 5000) {
        return 10;
        }
        else if (prc >= 1000) {
        return 5;
        }
        else {
        return 1;
        }
    }
    else
    {
        return 5;
    }      
}

export const _nearPrc = function(prc, market, etfgubun) {
    var ho = _hogaUnit(prc, market, etfgubun);
    if (prc % ho === 0) return prc;
    var bt = prc - (prc % ho) + ho;
    var tt = prc - (prc % ho);
    if ((prc - tt) > (bt - prc)) {
        return bt;
    }
    return tt;
} 

export const GUID = () => {
    function _s4() {
      return ((1 + Math.random()) * 0x10000 | 0).toString(16).substring(1);
    }
    return _s4() + _s4() + '-' + _s4() + '-' + _s4() + '-' + _s4() + '-' + _s4() + _s4() + _s4();
}

export const Log = (...arg) => {
    if (process.env.NODE_ENV !== 'production') {
        console.log(...arg);
    }
}


export class Packet {
    constructor(xml) {
        this.m_xml = xml || '<PKT></PKT>';
    }

    get(tag, dv) {
        var xml = this.m_xml;
        var s = xml.indexOf("<" + tag + ">");
		//console.log(xml);
        if (s !== -1) {
            s = xml.indexOf(">", s + 1);
            var e = xml.indexOf("</" + tag + ">", s + 1);
            if (e !== -1) {
                return xml.substr(s + 1, e - s - 1);
            }
        }
        if(!dv) dv = "";
        return dv;
    }

    getV(tag, dv) {
        var v = this.get(tag, dv);
        return _N(v);
    };

    set(tag, v) {
    	if(v === undefined) v = '';
        var xml = this.m_xml;
        var s;

        if(xml.indexOf("<" + tag + ">") !== -1)
        {
        	var vv = this.get(tag);
            var ov = "<" + tag + ">" + vv + "</" + tag + ">";
            var nv = "<" + tag + ">" + v + "</" + tag + ">";
            s = xml.indexOf(ov);
            var ss = [];
            ss.push(xml.substring(0, s));
            ss.push(nv);
            ss.push(xml.substring(s+ov.length));
            this.m_xml = ss.join("");
        }
        else {
            s = xml.indexOf("</PKT>");
            if (s !== -1) {
                var kk = "<" + tag + ">" + v + "</" + tag + "></PKT>";
                this.m_xml = xml.substring(0, s) + kk;
            }
        }
    };

    has(tag) {
        let xml = this.m_xml;

        var s = xml.indexOf("<" + tag + ">");
        if (s !== -1) {
            return true;
        }
        return false;        
    };    
}

export function ParamObj(procNm, rt) {
    this.obj = { "procnm": procNm, "rtype": rt || "json", "params":{} };
    this.set = function(k, v) {
        this.obj["params"][k] = v;
    };
    this.toString = function() {
        return JSON.stringify(this.obj);
    };
}

export function XClass(obj) {
    var jb = {};

    var data = obj["jData"] || "";
    if(data != "")
    {
        var ob = _JSON(data);
        for(var k in ob) {
            jb[k] = ob[k];
        }
    }

    for(var k in obj) {
        if(k != "jData")
        {
            jb[k] = obj[k];
        }
    }

    return jb;
}
  