import React, { useState } from 'react';

import { WTContext } from "./lib/WT";
import { ParamObj, _JSON, XClass, _CP } from './lib/solon';
import { useCookies } from 'react-cookie';

import 'alertifyjs/build/css/alertify.css';
import './App.css';

import FixCmp from './cmp/fixbar';
import LoginCmp from './cmp/login';


function App() {
  const WT = React.useContext(WTContext);
  const [cookie, setCookie, removeCookie] = useCookies();

  React.useEffect(() => {

    return () => {

    }

  }, []);

  if (sessionStorage.getItem("id")) {
    return <FixCmp />
  } else {
    return <LoginCmp />

  }

  // return <FixCmp />
}

export default App;