import React, {useState, useRef} from 'react';
import {WTContext} from '../lib/WT';
import {date_format, date_format_before} from '../lib/solon';

import MonitorGraph from './monitoring_graph1';
import MonitorGraph2 from './monitoring_graph2';

import monitoring_ring from '../images/monitoring_ring.png';


function Monitor3Cmp(props)  {
    const WT = React.useContext(WTContext);
    const nm = WT.currentObj.nm;

    React.useEffect(()=>{
        
        if(WT.currentObj === null){
            WT.currentObj = {e:0, nm:'TEST-01-0001' }
        }

        // const unit = WT.currentObj.e;
        // const nm = WT.currentObj.nm;
        // console.log(nm);
        // console.log(props.startDay);
        // console.log(props.endDay);

        graphLoading();

        return ()=> {

        }
    }, []);


    function graphLoading () {
        WT.Post({qry:"usp_weight_lst", unit:nm, start_dt:startDay.current, end_dt:endDay.current, rtnType: "json"}).then(a => {
            var a = JSON.parse(a);
            // console.log(a.rows)
            setGraph(a.rows);
        });

    }

	Date.prototype.addMonth = function(monthValue){
		this.setMonth(this.getMonth() + monthValue);
		return this;	
	};

	Date.prototype.getString = function(){
		return [this.getFullYear(), this.getMonth()+1 ,this.getDate()]
				.map(v => v < 10 ? "0" + v : v)
				.join("-");
	};


    const [graph, setGraph] = useState([]);
    const startDay = useRef(new Date().addMonth(-1).getString());
    const endDay = useRef(new Date().getString());
    function dayChange1(e) {
        startDay.current = e.target.value;
        graphLoading();
        // console.log(e.target.value);
    }
    function dayChange2(e) {
        endDay.current = e.target.value;
        graphLoading();
        // console.log(e.target.value);
    }




    return <div className="monitor_contents_btm">
                <div className="monitor_date">
                    <input type="date" id="day-month" value={startDay.current} onChange={dayChange1} />
                    <input type="date" id="day-month" value={endDay.current} onChange={dayChange2} />
                </div>                                
                <div className="monitor_graph">
                    <MonitorGraph list={graph} />
                </div>
            </div>


}

export default Monitor3Cmp;