import { _M, _N , _RATE, _RATA, _CT} from "./solon";
import {DataPrc} from './DataPrc';
import {WTContext} from "./WT";

class DataT {
    constructor() {
        this.shcode = '';
        this.hname = '';
        this.price = 0;
        this.drate = 0.00;
        this.change = 0;
        this.value = 0;
        this.volume = 0;
        this.high = 0;
        this.low = 0;
        this.open = 0;
        this.sign = 3;
        this.market = 1;
        this.etfgubun = 0;    
        this.uplmtprice = 0;    
    }

    setDP(dp) {
        this.market = _N(dp.mst.market);
        this.etfgubun = _N(dp.mst.etfgubun);
        this.uplmtprc = dp.mst.uplmtprc;

        this.shcode = dp.shcode;
        this.hname = dp.mst.hname;

        this.price = dp.price || dp.kprc;
        this.drate = dp.drate;
        this.change = dp.change;
        this.volume = dp.volume;
        this.open = dp.open || dp.kprc;
        this.high = dp.high || dp.kprc;
        this.low = dp.low || dp.kprc;
        this.sign = _N(dp.sign);
        this.value = dp.value;
        this.kprc = (dp.mst.kprc||dp.open);

        // this.B1 = THelper.nearPrc(this.HIGH - this.HIGH*8/100, this.market, this.etfgubun);
        // this.B2 = THelper.nearPrc(this.HIGH - this.HIGH*10/100, this.market, this.etfgubun);
        // this.B3 = THelper.nearPrc(this.HIGH - this.HIGH*12/100, this.market, this.etfgubun);

    }


    get amt() {
        return Math.floor(this.value * 1000000 / 100000000);
    }

}

export class dataJAN extends DataT {
    constructor() {
        super();
        this.aqty = 0;
        this.buy_amt = 0;
        this.buy_fee = 0;
        this.buy_fee1 = 0;
        this.buy_qty = 0;
        this.buyprc = 0;
        this.buyprc1 = 0;
        this.over_days = 0;
        this.qty = 0;
        this.rbuyamt = 0;
        this.reg_hms =  "";
        this.reg_ymd = "";
        this.sell_amt = 0;
        this.sell_fee = 0;
        this.sell_fee1 = 0;
        this.sell_oqty = 0;
        this.sell_qty = 0;
        this.sell_tax = 0;
        this.tbuy_amt = 0;
        this.tbuy_qty = 0;
    }

    get profit() {
        return (this.price - this.buyprc) * this.qty;
    }

    get profitrt() {
        return (this.price - this.buyprc) * this.qty * 100.0 / this.buy_amt;
    }

    parseJAN(s) {
        _CT(s, this);
        // this.shcode = s.shcode;
        // this.hname = s.hname;
        // this.aqty = s.aqty;
        // this.buy_amt = s.buy_amt;
        // this.buy_fee = s.buy_fee;
        // this.buy_fee1 = s.buy_fee1;
        // this.buy_qty = s.buy_qty;
        // this.buyprc = s.buyprc;
        // this.buyprc1 = s.buyprc1;
        // this.over_days = s.over_days;
        // this.qty = s.qty;
        // this.rbuyamt = s.rbuyamt;
        // this.reg_hms =  s.reg_hms;
        // this.reg_ymd = s.reg_ymd
        // this.sell_amt = s.sell_amt;
        // this.sell_fee = s.sell_fee;
        // this.sell_fee1 = s.sell_fee1;
        // this.sell_oqty = s.sell_oqty;
        // this.sell_qty = s.sell_qty;
        // this.sell_tax = s.sell_tax;
        // this.tbuy_amt = s.tbuy_amt;
        // this.tbuy_qty = s.tbuy_qty;
    }
}

export class dataFAV extends DataT {
    constructor() {
        super();
    }    
}



export class THelper {
    static WT = null;   //APP 에서 셋

    static getDDay(k, d) {

        if(d && d.length >= 8)
        {
            var dd = _N(d.substring(0, 8));
            var n = 0, m = 0, x = 0;

            for(var i = 0; i < THelper.WT.YMDs.length; i++) {
                if( dd === THelper.WT.YMDs[i]) {
                    m = i;
                }
                if (_N(k) === THelper.WT.YMDs[i]) {
                    n = i;
                }
            }

            x = n - m;
            if (x == 0) {
                return "(D)";
            }

            if (x > 0) {
                return "(D+{0})".format(x, dd);
            }
        }
        return "";
    }  

    static getChetime(t) {
        if(t) {
            if(t.length == 6) {
                return t.substring(0,2) + ":" + t.substring(2,4) + ":" + t.substring(4);
            } else if (t.length == 14) {
                return t.substring(4,6) + "/" + t.substring(6, 8) + " " + t.substring(8,10) + ":" + t.substring(10, 12);
            }   
            return t;
        }
        return "";
    }

    static getDataT(zone) {
        switch(zone) 
        {
            case "JAN": return new dataJAN();
            case "FAV": return new dataFAV();
            default:
                return new DataT();
        }
    }

    static rRate(v, c, r) {
        return <div>{_RATE(v)}</div>;
    }

    static rRata(v, c, r) {
        return <div>{_RATA(v)}</div>;
    }

    static rPrice(v, c, r) {
        return <div className='sc-3'>{_M(v)}</div>;
    }

    static rPriceSign(v, c, r) {
        return <div className={'sc-{0}'.format(r.sign||3)}>{_M(v)}</div>;
    }

    static rAmt(v, c, r) {
        return _M(v);
    }

    static rKprc(v, c, r) {
        return _M(v);
    }



    static getColumns(zone) {
        switch(zone)
        {
            case "JAN": 
                return [{
                   fld:'hname',
                   label:'종목명',
                   cls:'hname'
                },{
                    fld:'buy_amt',
                    label:'매입총액',
                    cls:'price',
                    renderer:THelper.rPrice
                },{
                    fld:'profit',
                    label:'평가손익',
                    cls:'price',
                    renderer:THelper.rPrice
                },{
                    fld:'profitrt',
                    label:'수익율(%)',
                    cls:'price',
                    renderer:THelper.rRate
                },{
                    fld:'buyprc',
                    label:'평균단가',
                    cls:'price',
                    renderer:THelper.rPrice
                },{
                    fld:'price',
                    label:'현재가',
                    cls:'price',
                    renderer:THelper.rPrice
                },{
                    fld:'drate',
                    label:'등락률(%)',
                    cls:'price',
                    renderer:THelper.rRata

                },{
                    fld:'qty',
                    label:'보유수량',
                    cls:'price',
                    renderer:THelper.rPrice

                },{
                    fld:'aqty',
                    label:'가능수량',
                    cls:'price',
                    renderer:THelper.rPrice

                },{
                    fld:'r_over_days',
                    label:'오버가능일',
                    cls:'price',
                    renderer:THelper.rPrice

                }];
            default:
                return [{
                    fld:'hname',
                    label:'종목명'
                 },{
                    fld:'price',
                    label:'현재가',
                    cls:'price',
                    renderer:THelper.rPrice
                 },{
                     fld:'amt',
                     label:'거래(억)'
                }];
        }
    }

    static hogaUnit(prc, market, etf) {
        var etfgubun = etf || 0;
        
        if (etfgubun > 0)
        {
          return 5;
        }
        else if (market == 2) {
          if (prc >= 50000) {
            return 100;
          }
          else if (prc >= 10000) {
            return 50;
          }
          else if (prc >= 5000) {
            return 10;
          }
          else if (prc >= 1000) {
            return 5;
          }
          else {
            return 1;
          }
        }
        else if (market == 1)
        {
          if (prc >= 500000) {
            return 1000;
          }
          else if (prc >= 100000) {
            return 500;
          }
          else if (prc >= 50000) {
            return 100;
          }
          else if (prc >= 10000) {
            return 50;
          }
          else if (prc >= 5000) {
            return 10;
          }
          else if (prc >= 1000) {
            return 5;
          }
          else {
            return 1;
          }
        }
        else
        {
          return 5;
        }      
      }
      
      static nearPrc(prc, market, etfgubun) {
          var ho = THelper.hogaUnit(prc, market, etfgubun);
          if (prc % ho == 0) return prc;
          var bt = prc - (prc % ho) + ho;
          var tt = prc - (prc % ho);
          if ((prc - tt) > (bt - prc)) {
              return bt;
          }
          return tt;
      }   

}
