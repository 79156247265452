Date.prototype.addDays = function (days) {
    var date = new Date(this.getTime());
    date.setDate(date.getDate() + days);
    return date;
};

Date.prototype.format = function (f) {
    if (!this.valueOf()) return " ";

    var weekName = [
        "일",
        "월",
        "화",
        "수",
        "목",
        "금",
        "토",
    ];
    var d = this;
    var h;

    return f.replace(/(yyyy|yy|MM|dd|E|hh|mm|ss|a\/p)/gi, function ($1) {
        switch ($1) {
        case "yyyy":
            return d.getFullYear();
        case "yy":
            return (d.getFullYear() % 1000).zeroFill(2);
        case "MM":
            return (d.getMonth() + 1).zeroFill(2);
        case "dd":
            return d.getDate().zeroFill(2);
        case "E":
            return weekName[d.getDay()];
        case "HH":
            return d.getHours().zeroFill(2);
        case "hh":
            return ((h = d.getHours() % 12) ? h : 12).zeroFill(2);
        case "mm":
            return d.getMinutes().zeroFill(2);
        case "ss":
            return d.getSeconds().zeroFill(2);
        case "a/p":
            return d.getHours() < 12 ? "오전" : "오후";
        default:
            return $1;
        }
    });
};


String.prototype.zeroFill = function (len) {
    var s = "", 
        i = 0;
    var xlen = len - this.length;

    while(i++ < xlen) {
        s += "0";
    }
    return (s + this).substring(0, len);
};

Number.prototype.zeroFill = function (len) {
    return this.toString().zeroFill(len);
};

String.prototype.trim = function() {
	var s = this,
	trimRegex = /^[\x09\x0a\x0b\x0c\x0d\x20\xa0\u1680\u180e\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200a\u2028\u2029\u202f\u205f\u3000]+|[\x09\x0a\x0b\x0c\x0d\x20\xa0\u1680\u180e\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200a\u2028\u2029\u202f\u205f\u3000]+$/g;
	return s.replace(trimRegex, "");
};

String.prototype.format = String.prototype.format = function() {
    var s = this,
        i = arguments.length;

    while (i--) {
        s = s.replace(new RegExp('\\{' + i + '\\}', 'gm'), String(arguments[i]));
    }
    return s;
};