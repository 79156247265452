import React, { useState } from 'react';
import { WTContext } from '../lib/WT';
import { lpad } from '../lib/solon';


function StatusCmp(props) {
    const WT = React.useContext(WTContext);

    // let timerId = setInterval(() => {
    //     callStatus()
    // }, 2000);
    // setTimeout(() => {
    //     alert('정지')
    // }, 6000);


    React.useEffect(() => {
        loadList();

        return () => {
        }

    }, []);

    function loadList() {
        console.log('전체 현황 load...');
        WT.Post({ qry: "usp_monit_lst", rtnType: "json" }).then(data => {
            var data2 = JSON.parse(data);
            setList(data2.rows);
            // console.log(data2.rows)
        });
        clearTimeout(WT.timer);
        WT.timer = setTimeout(loadList, 60000);
    }

    // const [units, setUnits] = useState([]);
    function openM(e, nm) {
        // props.nm(nm);
        props.setGubun(2);
        props.setUnit(e);

        WT.currentObj = {
            e: e,
            nm: nm
        }
    }

    const [list, setList] = useState([]);
    // function callStatus() {
    //     WT.Post({qry:"usp_monit_lst", rtnType: "json"}).then(data => {
    //         var data2 = JSON.parse(data);
    //         setList(data2.rows);
    //     });
    // }


    return <div className="status right">
        <div className="right_top">
            <p className="all-title">VITZRO NEXTECH</p>
        </div>
        {/* 운전 - 데이터가 들어오고 운전중일때(stat: 운전중)
        대기중 - 데이터가 들어오고 운전중이 아닐때(stat: 정상)
        에러 - 에러상태이고 데이터가 들어오지 않을때(stat: ERROR)
        오프라인 - 에러상태가 아니고 데이터가 들어오지 않을때(stat: OFFLINE) */}
        <div className="status_contents">
            <div className="status_contents_top">
                <dl className="signal">
                    <dt>범례 :</dt>
                    <dd><label className="work"></label><span>운전중</span></dd>
                    <dd><label className="stop"></label><span>대기중</span></dd>
                    <dd><label className="error"></label><span>ERROR</span></dd>
                    <dd><label className="offline"></label><span>OFFLINE</span></dd>
                </dl>
            </div>
            <div className="status_contents_btm">
                <ul className="signal">
                    {list.map((item, index) => {
                        if (item.stat === "운전중") {
                            return <li key={index} onClick={() => openM(index, item.unit)}><span>{item.unit}</span><label className="work"></label></li>
                        } else if (item.stat === "정상") {
                            return <li key={index} onClick={() => openM(index, item.unit)}><span>{item.unit}</span><label className="stop"></label></li>
                        } else if (item.stat === "ERROR") {
                            return <li key={index} onClick={() => openM(index, item.unit)}><span>{item.unit}</span><label className="error"></label></li>
                        } else if (item.stat === "OFFLINE") {
                            return <li key={index} onClick={() => openM(index, item.unit)}><span>{item.unit}</span><label className="offline"></label></li>
                        }
                    })}

                </ul>
            </div>


        </div>
    </div>
}

export default StatusCmp;