import React, { useState } from 'react';
import { WTContext } from '../lib/WT';
import alertify from 'alertifyjs';

function LoginCmp({ show, openPopup }) {
    const WT = React.useContext(WTContext);

    React.useEffect(() => {
        WT.Post({ qry: "usp_mbr_ins", rtnType: "json" }).then(data => {
            var data2 = JSON.parse(data);
            console.log(data);
        });

        return () => {

        }
    }, []);


    const [id, setId] = useState('');
    const [pw, setPw] = useState('');

    function onChangeId(e) {
        setId(e.target.value);
    }

    function onChangePw(e) {
        setPw(e.target.value);
    }

    const handleKeyPressId = (e) => {
        if (e.key === "Enter") {
            e.target.nextSibling.focus();
            if (pw.length > 0) {
                onSubmit();
            }
        }
    }

    const handleKeyPressPw = (e) => {
        if (e.key === "Enter") {
            onSubmit();
        }
    }

    function onSubmit(e) {
        //e.preventDefault();

        WT.Post({ qry: "usp_adm_login", id: id, pw: pw }).then(data => {
            if (data === "ID") {
                alertify.alert("VITZRO NEXTECH", "잘못된 정보 입니다.");
            } else if (data === "PW") {
                alertify.alert("VITZRO NEXTECH", "잘못된 정보 입니다.");
            } else if (data === "OK") {
                sessionStorage.setItem('id', id);
                console.log(sessionStorage);
                document.location.href = "/"
            }
        });
    }

    return <div className="login_wrap">
        <div className="login_top">
            <p className="all-title">VITZRO NEXTECH</p>
        </div>

        <section className="login">
            <div className="login_contents">
                <p className="login_title">Login</p>

                <form onSubmit={onSubmit}>
                    <input type="text" placeholder="ID" value={id} onChange={onChangeId} onKeyPress={handleKeyPressId} />
                    <input type="password" placeholder="Password" value={pw} onChange={onChangePw} onKeyPress={handleKeyPressPw} />
                </form>

                <a href="#" onClick={onSubmit}>LOGIN</a>
            </div>
        </section>
    </div>;
}



export default LoginCmp;