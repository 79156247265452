import React, { useState } from 'react';
import { WTContext } from '../lib/WT';
import alertify from 'alertifyjs';

function Admin(props) {
    const WT = React.useContext(WTContext);
    const [seq, setSeq] = useState('');
    const [id, setId] = useState('');
    const [pw, setPw] = useState('');
    const [list, setList] = useState([]);

    React.useEffect(() => {

        // console.log(edit);
        loadList();

        return () => {

        }
    }, []);

    function loadList() {
        WT.Post({
            qry: "usp_mbr_lst",
            rtnType: "json"
        }).then(data => {
            var data2 = JSON.parse(data);
            console.log(data2.rows);
            setList(data2.rows);
        });
    }

    function onChangeId(e) {
        setId(e.target.value);
        // console.log(e.target.value);
    }
    function onChangePw(e) {
        setPw(e.target.value);
        // console.log(e.target.value);
    }

    function onSubmit(e) {
        e.preventDefault();

        if (id === '') {
            alertify.alert("VITZRO NEXTECH", "아이디를 입력해 주세요.");
            return;
        } else if (pw === '') {
            alertify.alert("VITZRO NEXTECH", "비밀번호를 입력해 주세요.");
            return;
        }

        WT.Post({
            qry: "usp_mbr_ins",
            seq: 0,
            id: id,
            pw: pw
        }).then(data => {
            loadList();
            setId('');
            setPw('');
        });

    }

    function onRemove(seq) {
        alertify.confirm("VITZRO NEXTECH", "삭제 하시겠습니까?", function () {
            WT.Post({
                qry: "usp_mbr_del",
                seq: seq
            }).then(data => {
                loadList()
            });
        }, function () {

        });
    }

    function onAdmin(seq) {
        alertify.alert("VITZRO NEXTECH", "관리자 계정은 삭제가 불가능 합니다.");
    }


    const [edit, setEdit] = useState(false);
    function onModi(seq, id, pw) {
        setSeq(seq);
        setEdit(true);
        setEditId(id);
        setEditPw(pw);
        // setEdit(edit => !edit)
    }

    const [editId, setEditId] = useState('');
    const [editPw, setEditPw] = useState('');
    function onModiId(e) {
        setEditId(e.target.value);
    }

    function onModiPw(e) {
        setEditPw(e.target.value);
    }

    function onSave(seq) {
        // e.preventDefault();

        const findindex = list.findIndex(i => i.seq === seq);
        const newList = list;
        if (findindex != -1) {
            WT.Post({ qry: "usp_mbr_ins", seq: seq, id: editId, pw: editPw }).then(data => { });
            newList[findindex] = { ...newList[findindex], id: editId, pw: editPw }
        }

        setEdit(false);
    }





    return <div className="account right">
        <div className="right_top">
            <p className="all-title">VITZRO NEXTECH</p>
        </div>

        <div className="sns_contents">
            <div className="add_box">
                <form className="admin_form" onSubmit={onSubmit}>
                    <input type="text" className="id" placeholder="ID" onChange={onChangeId} value={id} />
                    {/* <input type="text" className="pw" placeholder="PASSWORD" onChange={onChangePw} value={pw} /> */}
                    <input type="password" className="pw" placeholder="PASSWORD" onChange={onChangePw} value={pw} />
                    <button onClick={onSubmit}>생성</button>
                </form>
            </div>


            <div className="table_wrap" >
                <table className="idtable">
                    <thead>
                        <tr>
                            <td width="5%">NO</td>
                            <td width="20%">ID</td>
                            <td width="20%">PASSWORD</td>
                            <td width="20%"></td>
                            <td width="15%">관리</td>
                        </tr>
                    </thead>
                    <tbody>
                        {edit ?
                            <tr>
                                <td>{seq}</td>
                                <td><input type="text" value={editId} onChange={onModiId} /></td>
                                <td><input type="password" value={editPw} onChange={onModiPw} /></td>
                                <td></td>
                                <td className="save idt_btn"><button onClick={() => onSave(seq)}>저장</button></td>
                            </tr>
                            :
                            list.map((item, index) => (
                                item["id"] == 'admin' ?
                                    <tr key={item["seq"]}>
                                        <td>{item["seq"]}</td>
                                        <td><input type="text" placeholder={item["id"]} value={item["id"]} readOnly /></td>
                                        <td><input type="password" placeholder={item["pw"]} value={item["pw"]} readOnly /></td>
                                        <td></td>
                                        <td className="idt_btn">
                                            <button className="save" onClick={() => onModi(item["seq"], item["id"], item["pw"])}>수정</button>
                                            <button className="delete" onClick={() => onAdmin(item["seq"])} >삭제</button>
                                        </td>
                                    </tr>
                                :
                                    <tr key={item["seq"]}>
                                        <td>{item["seq"]}</td>
                                        <td><input type="text" placeholder={item["id"]} value={item["id"]} readOnly /></td>
                                        <td><input type="password" placeholder={item["pw"]} value={item["pw"]} readOnly /></td>
                                        <td></td>
                                        <td className="idt_btn">
                                            <button className="save" onClick={() => onModi(item["seq"], item["id"], item["pw"])}>수정</button>
                                            <button className="delete" onClick={() => onRemove(item["seq"])}>삭제</button>
                                        </td>
                                    </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    </div>
}



export default Admin;