import React, { useState, useRef } from 'react';
import { WTContext } from '../lib/WT';
import { date_format, date_format_before } from '../lib/solon';

import Search from '../images/Search.png';
import movement from '../images/movement.png';
import throughput from '../images/throughput.png';

import Monitor1Cmp from './monitoring_1';
import Monitor2Cmp from './monitoring_2';
import Monitor3Cmp from './monitoring_3';
import Monitor4Cmp from './monitoring_4';
import MonitorGraph from './monitoring_graph1';
import MonitorGraph2 from './monitoring_graph2';


function MonitorCmp(props) {
    const WT = React.useContext(WTContext);
    const [click, setClick] = useState('T11');

    React.useEffect(() => {
        // if(WT.currentObj === null){
        //     WT.currentObj = {e:0, nm:'TEST-01-0001' }
        // }
        // const unit = WT.currentObj.e;
        // const nm = WT.currentObj.nm;
        // console.log(props.nm);

        WT.selgraphitem = 'T-Mix';
        loadList();

        return () => {
        }
    }, []);

    function loadList() {
        console.log('상세 모니터링 load...');
        WT.Post({ qry: "usp_monit_lst", rtnType: "json" }).then(data => {
            var data2 = JSON.parse(data);
            //console.log(data2.rows[props.unit]);
            let jj = JSON.parse(data2.rows[props.unit].jdata);
            jj.accm = data2.rows[props.unit].accm;
            setData(jj);
            setTxt(data2.rows[props.unit].unit);
            setErrorCode(JSON.parse(data2.rows[props.unit].jdata)["에러정보"].replace(/ /g, '').split(""));
            //console.log(JSON.parse(data2.rows[props.unit].jdata)["에러정보"].split(""));
        });

        clearTimeout(WT.timer);
        WT.timer = setTimeout(loadList, 60000);
    }

    const [errorCode, setErrorCode] = useState([]);

    const [txt, setTxt] = useState('');
    function handleChange(e) {
        setTxt(e.target.value);
        //console.log(txt);
    }

    const [data, setData] = useState('');
    // console.log(data)
    function handleSearch(e) {
        props.tab(1);

        return;
        e.preventDefault();

        WT.Post({ qry: "usp_monit_lst", rtnType: "json" }).then(data => {
            var data2 = JSON.parse(data);
            // console.log(data2.rows)
            var findindex = data2.rows.findIndex((e) => e["unit"] === txt)
            if (findindex === -1) {
                alert('없는 유닛 입니다');
            } else {
                let jj = JSON.parse(data2.rows[findindex].jdata);
                jj.accm = data2.rows[findindex].accm;
                setData(jj);
                setErrorCode(JSON.parse(data2.rows[props.unit].jdata)["에러정보"].replace(/ /g, '').split(""));

                // console.log(txt)
                WT.currentObj = {
                    e: findindex,
                    nm: txt
                }
            }
        });
    }

    return <div className="monitor right">
        <div className="right_top">
            <p className="all-title">VITZRO NEXTECH</p>
        </div>
        <div className="search" onClick={handleSearch}>
            <form onSubmit={handleSearch}>
                <ul>
                    <li>
                        <img onClick={handleSearch} src={Search} alt="돋보기" />
                        <input type="search" value={txt} onChange={(e) => handleChange(e)} placeholder="탄화기 번호 입력" />
                    </li>
                </ul>
            </form>
        </div>
        <div className="monitor_contents">

            <Monitor1Cmp data={data} errorCode={errorCode} />

            <Monitor2Cmp data={data} setClick={setClick} />


            <div className="monitor_contents_third">
                <div className="monitor_contents_top">
                    <h3 className="monitor_contents_title">처리량 확인</h3>
                </div>

                <Monitor3Cmp />
            </div>


            <div className="monitor_contents_forth">
                <div className="monitor_contents_top">
                    <h3 className="monitor_contents_title">{WT.selgraphitem}</h3>
                </div>
                <Monitor4Cmp click={click} />
            </div>

        </div>

    </div>
}

export default MonitorCmp;