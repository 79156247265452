import React, { useState } from 'react';
import { WTContext } from '../lib/WT';

function ErrorCmp(props) {
    const WT = React.useContext(WTContext);

    React.useEffect(() => {
        loadList();

        return () => {

        }
    }, []);

    function loadList() {
        console.log('Error Messages load...');
        WT.Post({ qry: "usp_err_lst", rtnType: "json" }).then(data => {
            var data2 = JSON.parse(data);
            // console.log(data2.rows);
            setList(data2.rows);
        });

        clearTimeout(WT.timer);
        WT.timer = setTimeout(loadList, 60000);
    }

    const [list, setList] = useState([]);


    return <div className="error right">
        <div className="right_top">
            <p className="all-title">VITZRO NEXTECH</p>
        </div>

        <div className="error_contents">
            <div className="table_wrap">
                <table>
                    <thead>
                        <tr>
                            <td width="5%">NO</td>
                            <td width="15%">발생 일자</td>
                            <td width="10%">장비명</td>
                            <td width="10%">Error Code</td>
                            <td width="40%">불량내용</td>
                            <td width="10%">담당자(사용자)</td>
                            <td width="10%">문자발송</td>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((item) => (
                            <tr key={item["seq"]}>
                                <td>{item.seq}</td>
                                <td>{item["dt"] + " " + item["tm"]}</td>
                                <td>{item["unit"]}</td>
                                <td>{item["err_code"]}</td>
                                <td>{item["err_desc"]}</td>
                                <td>{item["nms"]}</td>
                                <td>{item["sms"]}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    </div>;
}

export default ErrorCmp;