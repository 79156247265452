import React, {useState} from 'react';
import {WTContext} from '../lib/WT';
// import {Stage, Layer, Rect, Circle, Line, Text} from 'react-konva';
import Chart from 'react-apexcharts';
import {date_format, date_format_before} from '../lib/solon';

import movement from '../images/movement.png';
import throughput from '../images/throughput.png';
import graph1 from '../images/graph1.jpg';
import graph2 from '../images/graph2.jpg';


function MonitorGraph(props)  {
    const WT = React.useContext(WTContext);

    React.useEffect(()=>{

        return ()=> {

        }
    },[]);

    // const [list, setGraph1] = useState([props.list]);

    var options ={
        chart: {
            type:"bar",
            toolbar:{show:false},
        },
        plotOptions: {
            bar: { borderRadius: 4, },
            labels: { show: false, },
        },
        fill : {
            colors: '#4acb60',
        },
        dataLabels: {
            enabled: false
        },
        grid: {
            row: { colors: ['#383854'], opacity: 0.7},
            borderColor: '#5c5c71',
        },
        xaxis: {
            // categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            categories: props.list.map(a => a["dt"]),
            labels: { style: {colors: '#FFFFFF', fontSize: '10px'} },
        },
        yaxis: {
            // categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
            labels: { style: {colors: '#FFFFFF', fontSize: '10px'}, formatter: (val) => {return val.toFixed(2)} },
            forceNiceScale: true,
            // labels: { style: {colors: '#FFFFFF', fontSize: '10px'} },
        },
        tooltip: {
            enabled: true,
            // theme: "dark",
            // fillSeriesColor: true
        }
    }
    var series = [{
        name: "처리량 확인",
        data: props.list.map(a => a["weight"]),
        // data: [1,2,3, 4, 5],
    }]


    return (
        <Chart id="apex" type="bar" series={series} options={options} height="240" />

    )

}

export default MonitorGraph;