import React, { useState, useRef } from 'react';
import { WTContext } from '../lib/WT';
import { date_format, date_format_minute } from '../lib/solon';

import MonitorGraph from './monitoring_graph1';
import MonitorGraph2 from './monitoring_graph2';

import monitoring_ring from '../images/monitoring_ring.png';

function Monitor4Cmp(props) {
    const WT = React.useContext(WTContext);
    const nm = WT.currentObj.nm;
    const [graph, setGraph] = useState([]);
    const [ymax, setYmax] = useState(0);
    const [ymin, setYmin] = useState(0);
    const startDt = useRef(date_format(new Date(), '%Y-%m-%d 00:00:00'));
    const endDt = useRef(date_format(new Date(), '%Y-%m-%d 23:59:00'));
    const yy = 0;
    // console.log(props.click);

    React.useEffect(() => {
        graphLoading((list)=>{
            const numberArray = list.map(a => Number(a["col"]));
            const maxNumber = Math.max(...numberArray.filter(value => !isNaN(value)));
            const minNumber = Math.min(...numberArray.filter(value => !isNaN(value)));
            setYmax(maxNumber + 1);
            setYmin(minNumber - 1);
        });
        
        // console.log(startDt.current.split(' ')[0]);
        // console.log(startDt.current.split(' ')[1]);
        return () => {

        }
    }, [props.click]);


    function graphLoading(callback) {
        console.log('graphLoading');
        WT.Post({
            qry: "usp_graph_lst", 
            unit: nm, start_dt: startDt.current.split(' ')[0], 
            end_dt: endDt.current.split(' ')[0],
            start_tm: startDt.current.split(' ')[1], 
            end_tm: endDt.current.split(' ')[1], 
            col: props.click, rtnType: "json"
        }).then(a => {
            var a2 = JSON.parse(a);
            // console.log(a);
            setGraph(a2.rows);
            if(callback){
                callback(a2.rows);
            }
        });
    }



    function dateChange1(e) {
        startDt.current = e.target.value.replace('T', ' ');
        // console.log(e.target.value.replace('T', ' '))
        graphLoading();
    }
    function dateChange2(e) {
        endDt.current = e.target.value.replace('T', ' ');
        graphLoading();
    }

    const handleYmaxChange = (e) => {
        setYmax(e.target.value);
        //graphLoading();
    };

    const handleYminChange = (e) => {
        setYmin(e.target.value);
        //graphLoading();
    };

    return <div className="monitor_contents_third_btm_btm">
        <div className="monitor_date">
            <input type="datetime-local" value={startDt.current} onChange={dateChange1} />
            <input type="datetime-local" value={endDt.current} onChange={dateChange2} />
        </div>
        <div className="monitor_graph">
            <MonitorGraph2 list={graph} min={ymin} max={ymax} />
        </div>
        <div className="minMax">
            <span>
                <label>최대</label>
                <input type="text" value={ymax} onChange={handleYmaxChange} />
            </span>
            <span>
                <label>최소</label>
                <input type="text" value={ymin} onChange={handleYminChange} />
            </span>
        </div>
    </div>

}

export default Monitor4Cmp;