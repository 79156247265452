import React, { useState, useRef } from 'react';
import { WTContext } from '../lib/WT';

import monitoring_ring from '../images/monitoring_ring.png';

function Monitor2Cmp(props) {
    const WT = React.useContext(WTContext);

    React.useEffect(() => {
        // console.log(props.data)
        return () => {

        }
    }, []);


    function onClick(s, el) {
        props.setClick(prev => s);
        let ss = window.document.querySelectorAll('.monitor_contents_box_title');
        for (let i = 0; i < ss.length; i++) {
            ss[i].classList.remove('on');
        }
        el.target.classList.add('on');
        WT.selgraphitem = el.target.textContent;
        // console.log(WT.col.t)
    }

    function rn(num){
        if(isNaN(num)){
            return num;
        }else{
            return Number(num).toFixed(2).replace(/\.?0+$/, '');
        }        
    }

    return <div className="monitor_contents_second">
        <div className="monitor_contents_top">
            <h3 className="monitor_contents_title">MONITORING</h3>
        </div>

        <div className="monitor_contents_btm">
            <dl className="monitor_contents_box">
                <dt className="monitor_contents_box_title on" onClick={(el) => onClick("T11", el)}>T-Mix</dt>
                <dd className="monitor_contents_box_number">{rn(props.data["T11"])}</dd>
            </dl>

            <dl className="monitor_contents_box">
                <dt className="monitor_contents_box_title" onClick={(el) => onClick("T12", el)}>T-히터</dt>
                <dd className="monitor_contents_box_number">{rn(props.data["T12"])}</dd>
            </dl>

            <dl className="monitor_contents_box">
                <dt className="monitor_contents_box_title" onClick={(el) => onClick("T13", el)}>T-촉매1</dt>
                <dd className="monitor_contents_box_number">{rn(props.data["T13"])}</dd>
            </dl>

            <dl className="monitor_contents_box">
                <dt className="monitor_contents_box_title" onClick={(el) => onClick("T14", el)}>T-촉매2</dt>
                <dd className="monitor_contents_box_number">{rn(props.data["T14"])}</dd>
            </dl>

            <dl className="monitor_contents_box">
                <dt className="monitor_contents_box_title" onClick={(el) => onClick("T31", el)}>T-로</dt>
                <dd className="monitor_contents_box_number">{rn(props.data["T31"])}</dd>
            </dl>

            <dl className="monitor_contents_box">
                <dt className="monitor_contents_box_title" onClick={(el) => onClick("T32", el)}>T-출구</dt>
                <dd className="monitor_contents_box_number">{rn(props.data["T32"])}</dd>
            </dl>

            <dl className="monitor_contents_box">
                <dt className="monitor_contents_box_title" onClick={(el) => onClick("T21", el)}>T-냉in</dt>
                <dd className="monitor_contents_box_number">{rn(props.data["T21"])}</dd>
            </dl>

            <dl className="monitor_contents_box">
                <dt className="monitor_contents_box_title" onClick={(el) => onClick("T22", el)}>T-냉out</dt>
                <dd className="monitor_contents_box_number">{rn(props.data["T22"])}</dd>
            </dl>

            <dl className="monitor_contents_box">
                <dt className="monitor_contents_box_title" onClick={(el) => onClick("AI000", el)}>P-로</dt>
                <dd className="monitor_contents_box_number">{rn(props.data["AI000"])}</dd>
            </dl>

            <dl className="monitor_contents_box">
                <dt className="monitor_contents_box_title" onClick={(el) => onClick("AI014", el)}>P-냉in</dt>
                <dd className="monitor_contents_box_number">{rn(props.data["AI014"])}</dd>
            </dl>

            <dl className="monitor_contents_box">
                <dt className="monitor_contents_box_title" onClick={(el) => onClick("AI015", el)}>P-냉out</dt>
                <dd className="monitor_contents_box_number">{rn(props.data["AI015"])}</dd>
            </dl>

            <dl className="monitor_contents_box">
                <dt className="monitor_contents_box_title" onClick={(el) => onClick("AI002", el)}>F-N2</dt>
                <dd className="monitor_contents_box_number">{rn(props.data["AI002"])}</dd>
            </dl>

            <dl className="monitor_contents_box">
                <dt className="monitor_contents_box_title" onClick={(el) => onClick("AI003", el)}>F-air</dt>
                <dd className="monitor_contents_box_number">{rn(props.data["AI003"])}</dd>
            </dl>

            <dl className="monitor_contents_box">
                <dt className="monitor_contents_box_title" onClick={(el) => onClick('Kg_S', el)}>투입량</dt>
                <dd className="monitor_contents_box_number">{rn(props.data["Kg_S"])}</dd>
            </dl>

            <dl className="monitor_contents_box">
                <dt className="monitor_contents_box_title" onClick={(el) => onClick("Kg", el)}>현재무게</dt>
                <dd className="monitor_contents_box_number">{rn(props.data["Kg"])}</dd>
            </dl>
            <dl className="monitor_contents_box">
                <dt className="monitor_contents_box_title" onClick={(el) => onClick('"전력량"', el)}>전력량</dt>
                <dd className="monitor_contents_box_number">{rn(props.data["전력량"])}</dd>
            </dl>
            <dl className="monitor_contents_box">
                <dt className="monitor_contents_box_title" onClick={(el) => onClick("accm", el)}>누적운전</dt>
                <dd className="monitor_contents_box_number">{rn(props.data["accm"])}</dd>
            </dl>

            <dl className="monitor_contents_box">
                <dt className="monitor_contents_box_title" onClick={(el) => onClick("Current", el)}>P-A</dt>
                <dd className="monitor_contents_box_number">{rn(props.data["Current"])}</dd>
            </dl>

            <dl className="monitor_contents_box">
                <dt className="monitor_contents_box_title" onClick={(el) => onClick("Voltage", el)}>P-V</dt>
                <dd className="monitor_contents_box_number">{rn(props.data["Voltage"])}</dd>
            </dl>

            <dl className="monitor_contents_box">
                <dt className="monitor_contents_box_title" onClick={(el) => onClick("pp", el)}>P-P[kw]</dt>
                <dd className="monitor_contents_box_number">{rn(props.data["Current"] * props.data["Voltage"] / 1000)}</dd>
            </dl>

            <dl className="monitor_contents_box">
                <dt className="monitor_contents_box_title none">현재 상태</dt>
                <dd className="monitor_contents_box_number">{rn(props.data["시퀀스정보"])}</dd>
            </dl>
        </div>
    </div>


}

export default Monitor2Cmp;