import React, { useState } from 'react';
import { WTContext } from '../lib/WT';

import control from '../images/control.png';
import working from '../images/working.png';
import stop from '../images/stop.png';
import error_occurred from '../images/error_occurred.png';
import ball from '../images/ball.png';

function Monitor1Cmp(props) {
    const WT = React.useContext(WTContext);

    React.useEffect(() => {
        return () => {
        }
    }, []);


    function control_left(key) {
        // console.log("1", props.data);
        if (key === 0) {
            return <label className="gray"></label>
        } else if (key === 1) {
            return <label className="green"></label>
        } else {
            return <label className="stop"></label>
        }
    }

    function control_right(s) {
        if (s === '0') {
            return <label className="gray"></label>
        } else {
            return <label className="red"></label>
        }
    }
    console.log(props.errorCode.at(-1));
    return <div className="monitor_contents_first">
        <div className="monitor_contents_top">
            <h3 className="monitor_contents_title">CONTROL</h3>
        </div>
        <div className="monitor_contents_btm">
            <div className="monitor_contents_btm_left_ul">
                <ul className="signal">
                    <li>냉각수P{control_left(props.data["DO00"])}</li>
                    <li>N2{control_left(props.data["DO02"])}</li>
                    <li>히터{control_left(props.data["DO03"])}</li>
                    <li>Air Sol{control_left(props.data["DO04"])}</li>
                    <li>Scrubber{control_left(props.data["DO05"])}</li>
                    <li>요소수{control_left(props.data["DO06"])}</li>
                    <li>Drain P{control_left(props.data["DO07"])}</li>
                    <li>배출 Sol{control_left(props.data["DO08"])}</li>
                    <li>배출 B{control_left(props.data["DO09"])}</li>                    
                    <li>2류체 A{control_left(props.data["DO11"])}</li>                
                    <li>D/L 상 1{control_left(props.data["DI01"])}</li>
                    <li>D/L 상 2{control_left(props.data["DI09"])}</li>
                    <li>D/L 하 1{control_left(props.data["DI02"])}</li>
                    <li>W/L{control_left(props.data["DI03"])}</li>
                    <li>U/L{control_left(props.data["DI04"])}</li>
                    <li>정상/고장{props.errorCode.at(-1) == '1' ? <img src={error_occurred} /> : <img src={working} />}</li>
                </ul>
            </div>

            <div className="monitor_contents_btm_rigjt_ul">
                <ul className="signal">
                    <li>{control_right(props.errorCode[1])}1. Drain 불량</li>
                    <li>{control_right(props.errorCode[2])}2. 냉각수 부족</li>
                    <li>{control_right(props.errorCode[3])}3. N2 부족</li>
                    <li>{control_right(props.errorCode[4])}4. 무게증가</li>
                    <li>{control_right(props.errorCode[5])}5. 과열-구간</li>
                    <li>{control_right(props.errorCode[6])}6. 과열-냉각수</li>
                    <li>{control_right(props.errorCode[7])}7. 냉각수 압력</li>
                    <li>{control_right(props.errorCode[8])}8. 반응로 압력</li>
                    <li>{control_right(props.errorCode[9])}9. 정류기 에러</li>
                    <li>{control_right(props.errorCode[10])}10. 교반기 Inv</li>
                    <li>{control_right(props.errorCode[11])}11. 블로워 Inv</li>
                    <li>{control_right(props.errorCode[12])}12. TC-01</li>
                    <li>{control_right(props.errorCode[13])}13. TC-02</li>
                    <li>{control_right(props.errorCode[14])}14. TC-03</li>
                    <li>{control_right(props.errorCode[15])}15. 저울에러</li>
                    <li>{control_right(props.errorCode[16])}16. 피더 Inv</li>
                </ul>
            </div>
        </div>
    </div>

}

export default Monitor1Cmp;