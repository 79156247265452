import React from 'react';
import {EventEmitter} from 'events';
import {isArray, Packet, _JSON, _N, Log, _S, date_format, _CP, _CT} from "../lib/solon";
import { Buffer } from 'buffer';
import { DataPrc } from './DataPrc';
import { DataT, THelper, dataF, dataX, dataSF, dataHot, dataJM } from './DataT';

import PacketV from './PacketV';
import alertify from 'alertifyjs';

const zlib = require('react-zlib-js');

class WT extends EventEmitter {
    constructor() {
        super();

        this.currentObj = {e:0, nm:''};
    }

    Log(...obj) {
        if(process.env.REACT_APP_DEBUG === "1") console.log(...obj);
    }

    async Post(body, headers = {}) {
        // const url = process.env.REACT_APP_AJAX;
        const url = "http://211.115.205.126/vitzroproc.php";
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            ...headers,
          },
          body: JSON.stringify(body),
        };
        const res = await fetch(url, options);
        const data = await res.text();
        if (res.ok) {
          return data;
        } else {
          throw Error(data);
        }
    }

    getOS() {
        var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
        if ( varUA.indexOf('android') > -1) {
            return "ANDROID";
        } else if ( varUA.indexOf("iphone") > -1||varUA.indexOf("ipad") > -1||varUA.indexOf("ipod") > -1 ) {
            return "IOS";
        } 

        return "DESKTOP";
    }


    fire(evtType, evtObj) {
        this.emit(evtType, evtObj);
    }

    on(evtType, callback) {
        this.addListener(evtType, callback);
    }

    off(evtType, callback) {
        this.removeListener(evtType, callback);
    }

}

export const WTContext = React.createContext(new WT());

export const ItemTypes = {
    CARD : 'card',
    STKCD: 'stkcd'
}
