import React, { useState } from 'react';
import { WTContext } from '../lib/WT';
import alertify from 'alertifyjs';
import x from '../images/x.png';

function SnsCmp(props) {
    const WT = React.useContext(WTContext);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [list, setList] = useState([]);

    React.useEffect(() => {
        loadList();

        return () => {

        }

    }, []);

    function loadList() {
        WT.Post({
            qry: "usp_sms_hp_lst",
            rtnType: "json"
        }).then(data => {
            var data2 = JSON.parse(data);
            console.log(data2.rows);
            setList(data2.rows);
        });
    }

    function onChangeName(e) {
        setName(e.target.value);
        // console.log(e.target.value);
    }
    function onChangePhone(e) {
        setPhone(e.target.value);
        // console.log(e.target.value);
    }


    function onSubmit(e) {
        e.preventDefault();

        WT.Post({
            qry: "usp_sms_hp_ins",
            hp: phone,
            nm: name
        }).then(data => {
            loadList();
            setName('');
            setPhone('');
        });

        //setList(list.concat({ phone, name }));

    }
    function onRemove(phone) {

        alertify.confirm("VITZRO NEXTECH", "삭제 하시겠습니까?", function () {
            WT.Post({
                qry: "usp_sms_hp_del",
                hp: phone
            }).then(data => {
                loadList();
            });
        }, function () {

        });

        //setList(list.filter((item) => item.phone !== phone));
    }


    return <div className="sns right">
        <div className="right_top">
            <p className="all-title">VITZRO NEXTECH</p>
        </div>

        <div className="sns_contents">
            <div className="add_box">
                <form onSubmit={onSubmit}>
                    <input type="text" className="name" placeholder="성함" onChange={onChangeName} value={name} />
                    <input type="text" className="phone_number" placeholder="전화번호" onChange={onChangePhone} value={phone} />
                    <button onClick={onSubmit} >등록</button>
                </form>
            </div>
            <div className="table_wrap">
                <table>
                    <thead>
                        <tr>
                            <td width="5%">NO</td>
                            <td width="15%">성함</td>
                            <td width="20%">전화번호</td>
                            <td width="50%"></td>
                            <td width="10%">관리</td>
                        </tr>
                    </thead>

                    <tbody>
                        {list.map((item, index) => (
                            <tr key={index}>
                                <td>{index}</td>
                                <td>{item["nm"]}</td>
                                <td>{item["hp"]}</td>
                                <td></td>
                                <td className="idt_btn"><button className="delete" onClick={() => onRemove(item["hp"])}>삭제</button></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
}

export default SnsCmp;